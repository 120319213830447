<template>
  <div class="position-relative d-flex overflow-hidden">
    <SideNavigation class="position-sticky-top" />
    <main
      class="d-flex flex-row flex-wrap flex-grow-1 w-auto h-100 overflow-scroll"
      @scroll="checkScroll"
      :style="$route.name !== 'Paiement' ? 'padding-bottom: 200px' : ''"
    >
      <div
        v-if="$route.name === 'Home'"
        id="defaultHome"
        class="flex-grow-1 d-flex justify-content-center flex-wrap pt-5 p-3 text-nowrap w-100 h-fit"
      >

        <div class="position-relative">
          <button
            class="Home-button position-absolute top-0 end-0 btn btn-outline-primary shadow-3-strong m-auto p-1 mt-2 me-2"
            :class="$store.state.app.theme === 'light' ? 'bg-white' : 'bg-cust-dark'"
            @click="$router.push({ name: 'CheckingPost' })"
          >
            <MDBIcon
              icon="plus"
              icon-style="fas"
              class="fa-fw"
              size="2x"
              :title="$tcust('router.CheckingPost')"
            />
          </button>

          <button
            class="btn btn-primary d-flex flex-column justify-content-center align-items-center m-3"
            @click="$router.push({ name: 'CheckingsList' })"
          >
            <MDBIcon
              icon="tasks"
              icon-style="fas"
              size="6x"
              class="my-2"
            />
            <span class="fw-bold fs-5 w-fit px-2">
              {{ $tcust('router.CheckingsList') }}
            </span>
          </button>
        </div>

        <div class="position-relative">
          <button
            class="Home-button position-absolute top-0 end-0 btn btn-outline-primary shadow-3-strong m-auto p-1 mt-2 me-2"
            :class="$store.state.app.theme === 'light' ? 'bg-white' : 'bg-cust-dark'"
            @click="$router.push({ name: 'AccommodationPost' })"
          >
            <MDBIcon
              icon="plus"
              icon-style="fas"
              class="fa-fw"
              size="2x"
              :title="$tcust('router.AccommodationPost')"
            />
          </button>

          <button
            class="btn btn-primary d-flex flex-column justify-content-center align-items-center m-3"
            @click="$router.push({ name: 'AccommodationsList' })"
          >
            <MDBIcon
              icon="building"
              icon-style="far"
              size="6x"
              class="my-2"
            />
            <span class="fw-bold fs-5 w-fit px-4">
              {{ $tcust('router.AccommodationsList') }}
            </span>
          </button>
        </div>

        <button
          class="btn btn-primary d-flex flex-column justify-content-center align-items-center m-3"
          @click="$router.push({ name: 'LicensesList' })"
        >
          <MDBIcon
            icon="business-time"
            icon-style="fas"
            size="6x"
            class="my-2"
          />
          <span class="fw-bold fs-5 w-fit">
            {{ $tcust('router.LicensesList') }}
          </span>
        </button>

        <button
          class="btn btn-primary d-flex flex-column justify-content-center align-items-center m-3"
          @click="$router.push({ name: 'Profil' })"
        >
          <MDBIcon
            icon="user"
            icon-style="far"
            size="6x"
            class="my-2"
          />
          <span class="fw-bold fs-5 w-fit">
            {{ $tcust('router.Profil') }}
          </span>
        </button>
      </div>

      <router-view
        v-else
        class="overflow-auto w-100 pt-4 pt-md-3"
      />

      <button
        v-show="scrolled"
        class="above custom Home-button Scroll-button btn btn-primary btn-icon shadow-3-strong position-absolute bottom-0 end-0 m-4 w-fit h-fit p-0"
        @click="scrollTop"
      >
        <MDBIcon
          icon="caret-square-up"
          icon-style="fas"
          size="3x"
          class="p-0 m-0"
        />
      </button>
    </main>
  </div>
</template>

<script setup>
import { MDBIcon } from "mdb-vue-ui-kit";
import SideNavigation from "@/components/navigation/SideNavigation";

import { ref } from "vue";

const checkScroll = () => {
  if (document.querySelector('main') && document.querySelector('main').scrollTop > 0) scrolled.value  = true;
  else scrolled.value = false;
};
const scrolled = ref(false);
const scrollTop = () => {
  document.querySelector('main').scrollTo({
    top: 0,
    behavior: 'smooth'
  });
};

// import Setup from "./HomeSetup";
// Setup();
</script>

<style lang="scss" scoped>
@import "styles/var.scss";

button:not(.Home-button) {
  min-width: 250px;
  min-height: 150px;
  max-width: 250px;
  max-height: 150px;
}
.light-theme .Scroll-button {
  &:not(:hover):not(:focus) {
    color: $gray-200 !important;
  }
  &:hover, &:focus {
    color: $gray-200 !important;
    background-color: #303030 !important;
  }
}
.dark-theme .Scroll-button {
  &:not(:hover):not(:focus) {
    color: #303030 !important; // main bgc
  }
  &:hover, &:focus {
    background-color: $gray-200 !important;
  }
}
.btn.Scroll-button {
  min-width: 36px !important;
  min-height: 36px !important;
  max-width: 36px !important;
  max-height: 36px !important;
}
#defaultHome {
  align-content: flex-start;
}
.bg-cust-dark {
  background-color: #303030;
}
</style>