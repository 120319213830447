<template>
  <div
    id="sideNavigation"
    class="position-relative mt-n1 h-100"
  >
    <div
      v-if="!['xl', 'xxl'].includes($store.state.app.windowSize)"
      class="burger position-absolute top-0 start-0 ps-1 text-center above"
    >
      <!-- Toogles -->
      <MDBBtn
        class="mt-3 ms-1 px-3 py-1"
        color="primary"
        aria-controls="#sidenav"
        :title="hidden ? $tcust('SideNavigation.show') : $tcust('SideNavigation.hide')"
        style="transition: transform 0.5s"
        :style="hidden ? '' : 'transform: translateX(80px)'"
        @click="hide(!hidden)"
      >
        <MDBIcon
          icon="bars"
          icon-style="fas"
          size="2x"
        />
      </MDBBtn>
    </div>

    <!-- Sidenav-->
    <MDBSideNav
      id="sideNav"
      v-model="sideNav"
      ontouchstart="event.stopImmediatePropagation()"
      ontouchend="event.stopImmediatePropagation()"
      :slim="slim"
      :slim-collapsed="!slimShow"
      :backdrop="false"
      :class="['xl', 'xxl'].includes($store.state.app.windowSize) ? 'position-relative' : 'position-absolute top-0 start-0'"
    >
      <MDBSideNavMenu
        accordion
        :class="
          !['xl', 'xxl'].includes($store.state.app.windowSize) ? 'pt-2 mt-5' : 'pt-3'
        "
      >
      <MDBSideNavItem>
          <MDBSideNavLink
            :title="!slim || slimShow ? '' : $tcust('router.Home')"
            @click="$router.push({ name: 'Home' })"
            :class="slim ? 'd-flex justify-content-center' : ''"
            class="mt-0 mt-sm-3"
          >
            <i
              class="material-symbols-outlined fs-3"
              :class="slim ? '' : 'me-3'"
            >
            home
            </i>
            <span class="sidenav-non-slim fs-5">
              {{ $tcust('router.Home') }}
            </span>
          </MDBSideNavLink>
        </MDBSideNavItem>

        <hr class="w-auto m-2">

        <MDBSideNavItem>
          <MDBSideNavLink
            :title="!slim || slimShow ? '' : $tcust('router.Profil')"
            @click="$router.push({ name: 'Profil' })"
            :class="slim ? 'd-flex justify-content-center' : ''"
          >
            <MDBIcon
              icon="user"
              icon-style="far"
              class="fa-fw"
              :class="slim ? '' : 'me-3'"
              size="lg"
            />
            <span class="sidenav-non-slim fs-5">
              {{ $tcust('router.Profil') }}
            </span>
          </MDBSideNavLink>
        </MDBSideNavItem>

        <hr class="w-75 m-auto my-2">

        <MDBSideNavItem>
          <MDBSideNavLink
            :title="!slim || slimShow ? '' : $tcust('router.CheckingsList')"
            @click="$router.push({ name: 'CheckingsList' })"
            :class="slim ? 'd-flex justify-content-center' : ''"
          >
            <MDBIcon
              icon="tasks"
              icon-style="fas"
              class="fa-fw"
              :class="slim ? '' : 'me-3'"
              size="lg"
            />
            <span class="sidenav-non-slim fs-5">
              {{ $tcust('router.CheckingsList') }}
            </span>
          </MDBSideNavLink>
        </MDBSideNavItem>

        <MDBSideNavItem v-if="!slim">
          <MDBSideNavLink
            :title="!slim || slimShow ? '' : $tcust('router.CheckingPost')"
            class="btn border-1 rounded-pill fs-6 w-fit h-75 me-auto ms-4 mt-0 mb-3 px-2 py-1"
            :class="$store.state.app.theme === 'light' ? 'btn-white border border-primary' : 'btn-outline-primary'"
            @click="$router.push({ name: 'CheckingPost' })"
          >
            <div class="d-flex flex-row align-items-center m-auto w-100 h-fit">
              <MDBIcon
                icon="plus"
                icon-style="fas"
                class="fa-fw ms-0 me-2"
              />
              <div class="SideNavigationFormLink">
                <span class="text-normal me-1">
                  {{ $tcust('router.CheckingPost') }}
                </span>
              </div>
            </div>
          </MDBSideNavLink>
        </MDBSideNavItem>

        <MDBSideNavItem v-else>
          <MDBSideNavLink
            :title="!slim || slimShow ? '' : $tcust('router.CheckingPost')"
            class="btn border-1 rounded-5 custom-pill m-auto mt-2 mb-3 p-0"
            :class="$store.state.app.theme === 'light' ? 'btn-white border border-primary' : 'btn-outline-primary'"
            @click="$router.push({ name: 'CheckingPost' })"
          >
            <div class="d-flex flex-row align-items-center m-auto w-fit h-fit">
              <MDBIcon
                icon="plus"
                icon-style="fas"
                class="fa-fw"
              />
            </div>
          </MDBSideNavLink>
        </MDBSideNavItem>

        <hr class="w-75 m-auto my-2">

        <MDBSideNavItem>
          <MDBSideNavLink
            :title="!slim || slimShow ? '' : $tcust('router.AccommodationsList')"
            @click="$router.push({ name: 'AccommodationsList' })"
            :class="slim ? 'd-flex justify-content-center' : ''"
          >
            <MDBIcon
              icon="building"
              icon-style="far"
              class="fa-fw"
              :class="slim ? '' : 'me-3'"
              size="lg"
            />
            <span class="sidenav-non-slim fs-5">
              <span>{{ $tcust('router.AccommodationsList') }}</span>
            </span>
          </MDBSideNavLink>
        </MDBSideNavItem>

        <MDBSideNavItem v-if="!slim">
          <MDBSideNavLink
            :title="!slim || slimShow ? '' : $tcust('router.AccommodationPost')"
            class="btn border-1 rounded-pill fs-6 w-fit h-75 me-auto ms-4 mt-0 mb-3 px-2 py-1"
            :class="$store.state.app.theme === 'light' ? 'btn-white border border-primary' : 'btn-outline-primary'"
            @click="$router.push({ name: 'AccommodationPost' })"
          >
            <div class="d-flex flex-row align-items-center m-auto w-100 h-fit">
              <MDBIcon
                icon="plus"
                icon-style="fas"
                class="fa-fw ms-0 me-2"
              />
              <div class="SideNavigationFormLink">
                <span class="text-normal me-1">
                  {{ $tcust('router.AccommodationPost') }}
                </span>
              </div>
            </div>
          </MDBSideNavLink>
        </MDBSideNavItem>

        <MDBSideNavItem v-else>
          <MDBSideNavLink
            :title="!slim || slimShow ? '' : $tcust('router.AccommodationPost')"
            class="btn border-1 rounded-5 custom-pill m-auto mt-2 mb-3 p-0"
            :class="$store.state.app.theme === 'light' ? 'btn-white border border-primary' : 'btn-outline-primary'"
            @click="$router.push({ name: 'AccommodationPost' })"
          >
            <div class="d-flex flex-row align-items-center m-auto w-fit h-fit">
              <MDBIcon
                icon="plus"
                icon-style="fas"
                class="fa-fw"
              />
            </div>
          </MDBSideNavLink>
        </MDBSideNavItem>

        <hr class="w-75 m-auto my-2">

        <MDBSideNavItem>
          <MDBSideNavLink
            :title="!slim || slimShow ? '' : $tcust('router.LicensesList')"
            @click="$router.push({ name: 'LicensesList' })"
            :class="slim ? 'd-flex justify-content-center' : ''"
            @keydown.tab="focusMain($event)"
          >
            <MDBIcon
              icon="business-time"
              icon-style="fas"
              class="fa-fw"
              :class="slim ? '' : 'me-3'"
              size="lg"
            />
            <span class="sidenav-non-slim fs-5">
              {{ $tcust('router.LicensesList') }}
            </span>
          </MDBSideNavLink>
        </MDBSideNavItem>
      </MDBSideNavMenu>
    </MDBSideNav>
  </div>
</template>

<script setup>
import {
  MDBSideNav,
  MDBSideNavMenu,
  MDBSideNavItem,
  MDBSideNavLink,
  MDBBtn,
  MDBIcon,
} from "mdb-vue-ui-kit";

import Setup from "./SideNavigationSetup";
const { focusMain, hide, hidden, sideNav, slim, slimShow } = Setup();
</script>

<style lang="scss" scoped>
.sidenav {
  transition: none;
}
.custom-pill {
  height: 1.5rem !important;
  width: 1.5rem !important;
}
a i:not(.fa-plus) {
  transform: scale(1.3);
}

.burger {
  @media (max-width: 360px) {
    margin: -0.5rem -0.25rem;
  }
}

.SideNavigationBrand {
  min-width: 40.0px;
  max-width: 40.0px;
  min-height: 32.7px;
  max-height: 32.7px;
}
.SideNavigationFormLink, .sidenav-non-slim {
  max-width: 153px;
  font-weight: normal !important;
}
</style>