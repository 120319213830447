import { ref, computed, onMounted, reactive, watch } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";

export default function Setup() {
  const store = useStore();
  const route = useRoute();
  const clickPath = computed(() => {
    return store.state.app.clickPath;
  });
  const windowSize = computed(() => {
    return store.state.app.windowSize;
  });

  const sideNav = ref(['xs', 'sm'].includes(windowSize.value) ? false : true);
  const slim = ref(!['xs', 'sm'].includes(windowSize.value) ? false : true);
  const slimShow = ref(false);

  const hide = (bool) => {
    if (['xl', 'xxl'].includes(windowSize.value)) {
      sideNav.value = true;
      slim.value = false;
    }
    else {
      slimShow.value = false;
      if (bool) {
        ['xs', 'sm'].includes(windowSize.value) ? sideNav.value = false : slim.value = true;
      }
      else {
        if (['xs', 'sm'].includes(windowSize.value)) sideNav.value = true;
        slim.value = false;
      }
    }
  };

  const hidden = computed(() => {
    return ['xs', 'sm'].includes(windowSize.value) ? !sideNav.value : slim.value
  });

  const layout = () => {
    try {
      let cont = document.getElementById("sideNavigation");
      let sideNav = document.getElementById("sideNav");
      // below = prevent animations
      sideNav.style.opacity = "0";
      sideNav.style.display = "none";
      hide(false);
      setTimeout(() => {
        sideNav.style.display = "";
        hide(true);
        setTimeout(() => {
          if (!['xs', 'sm'].includes(windowSize.value)) {
            cont.style.minWidth = sideNav.scrollWidth + 'px';
          }
          else {
            cont.style.minWidth = "0px";
          }
          setTimeout(() => {
            sideNav.style.opacity = "1";
          });
        });
      });
    } catch (error) {
      layout();
    }
  };

  const focusMain = (event) => {
    event.stopImmediatePropagation()
    let NextMain = document.querySelector('main a:not([disabled]), main button:not([disabled]), main input:not([disabled]), main textarea:not([disabled]), main [tabindex]:not([disabled]):not([tabindex="-1"])');
    setTimeout(() => {
      NextMain.focus();
      hide(true);
    });
  }

  onMounted(() => {
    layout();
  });

  const routeName = reactive(route);
  watch(routeName, () => {
    hide(true);
  });
  watch(windowSize, () => {
    layout();
  });
  watch(clickPath, () => {
    if (!clickPath.value.includes(document.getElementById("sideNavigation")))
      hide(true);
  });

  return {
    focusMain,
    hide,
    hidden,
    sideNav,
    slim,
    slimShow
  };
}
